<template>
  <div class="mb-2">
    <div class="col-md-12 mb-1 form-group form-outline">
      <label class="label_default label_blue">
        Mostrar Popup
      </label>
    </div>
    <div class="col-md-12 row">
      <div class="col-md-auto">
        <div tag="div" class="d-flex align-items-center form-check">
          <div class="mr-2 field-checkbox">
            <input
              class="field"
              type="checkbox"
              id="noPopup"
              :checked="!withPopup"
              :value="!withPopup"
              @change="
                $emit('update:withPopup', $event.target.checked ? false : true)
              "
            />
            <label class="description" for="noPopup"></label>
          </div>
          <p
            class="label_default _checkbox mb-2 list"
            @click="$emit('getCheckBox', $event.target)"
          >
            Sim
          </p>
        </div>
      </div>
      <div class="col-md-auto">
        <div class="d-flex align-items-center form-check">
          <div class="mr-2 field-checkbox">
            <input
              class="field"
              type="checkbox"
              id="withPopup"
              :checked="withPopup"
              :value="withPopup"
              @change="
                $emit('update:withPopup', $event.target.checked ? true : false)
              "
            />
            <label class="description" for="withPopup"></label>
          </div>
          <p
            class="label_default _checkbox mb-2 list"
            @click="$emit('getCheckBox', $event.target)"
          >
            Não
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["withPopup"]
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
